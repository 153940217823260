<template>
    <div class="main-view">
        <div class="m-title">
            <div @click="back" style="margin-right: 5px;width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;">
                <van-icon name="arrow-left" size="20"/>
            </div>
            绑定手机
        </div>

        <van-form @submit="onSubmit" ref="submitForm">
            <div class="form-view">
                <div style="color: #888888;font-size: 12px;text-align: left;margin: 0 15px;">
                    为了帐号安全，请及时绑定手机号码。
                </div>

                <span class="ds-label">手机号码</span>
                <van-field label-class="inline-label" placeholder="请输入手机号" style="margin-top: 2px;color: #ffffff;"  v-model="tel" clearable/>

                <span class="ds-label">验证码</span>
                <van-field label-class="inline-label" style="margin-top: 2px;"
                           v-model="verifyCode" type="number" placeholder="请输入验证码">
                    <template #button>
                        <van-count-down
                            v-show="showCountDown"
                            ref="countDown"
                            :time="60000"
                            :auto-start="false"
                            format="重新发送(ss秒)"
                            style="font-size: 12px;color: #888888"
                            @finish="finish"
                        />
                        <span style="color: #4375f8;font-size: 12px;" v-show="!showCountDown" @click="sendSmsForBind">发送验证码</span>
                    </template>
                </van-field>

                <div class="box-button">
                    <van-button type="info" block native-type="submit" size="small" :loading="loading" round style="width: 160px;"
                                color="#4375f8">
                        立即绑定
                    </van-button>
                </div>

            </div>
        </van-form>


    </div>
</template>
<script>

    import {Toast} from "vant";
    import {bindPhone, sendSms, webTokenRequest} from "@/api/request";
    import webConfig from "@/api/config";

    export default {
        name: 'app-tel',
        components: {
        },
        data() {
            return {
                tel: '',
                verifyCode: '',
                missShow: false,
                backShow: true,
                telPattern: /^1\d{10}$/,
                codePattern: /^\d{6}$/,
                showCountDown: false,
                showSendText: true,
                disabledSend: false,
                loading: false,
            }
        },
        mounted() {
            this.webConfig = webConfig;
        },
        methods: {
            back() {
                this.$router.back();
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                this.loading = true;
                let options = {
                    url:"/web/bindPhone",
                    data: {
                        phone: this.tel,
                        checkCode: this.verifyCode,
                    }
                };
                webTokenRequest(options).then((res) => {
                    this.loading = false;
                    if (res.data.success === true) {
                        Toast.success('手机号码绑定成功');
                        let user = JSON.parse(localStorage.getItem('user'));
                        user.phone = this.tel;
                        localStorage.setItem('user', JSON.stringify(user));

                        this.back();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            sendSmsForBind() {
                let ok = this.checkTel();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.disabledSend = true;
                this.showSendText = false;
                this.showCountDown = true;
                this.$refs.countDown.start();

                let options = {
                    url:"/web/sendSms",
                    data: {
                        phone: this.tel,
                        type: '2',
                    }
                };
                webTokenRequest(options).then((res) => {
                    if (res.data.success === true) {
                        Toast('验证码发送成功');
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            checkTel() {
                if (!this.tel) {
                    return "请输入手机号码";
                }
                if (!this.telPattern.test(this.tel)) {
                    return "请输入正确的手机号码";
                }
                return 'ok';
            },
            checkForm() {
                if (!this.tel) {
                    return "请输入手机号码";
                }
                if (!this.telPattern.test(this.tel)) {
                    return "请输入正确的手机号码";
                }
                if (!this.verifyCode) {
                    return "请输入验证码";
                }
                if (!this.codePattern.test(this.verifyCode)) {
                    return "验证码为6位数字";
                }
                return 'ok';
            },
            finish() {
                this.disabledSend = false;
                this.showSendText = true;
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #e6ecff;
    }
    .m-title {
        display: flex;
        font-size: 18px;
        color: #333333;
        width: 100%;
        height: 60px;
        text-align: left;
        align-items: center;
        padding-left: 10px;
        position: fixed;
        z-index: 999;
        background-color: #e6ecff;
    }
    .ds-label {
        display: inline-block;
        width: 100%;
        text-align: left;
        margin: 15px 15px 0;
        font-size: 14px;
    }

    /deep/.inline-label {
        color: #aaaaaa !important;
    }
    .van-cell{
        padding: 8px 14px;
    }
    .van-cell::after {
        border-bottom: 1px solid #333333;
    }
    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
    .form-view {
        margin: 60px 15px 0;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 20px 10px 20px;
    }
</style>
