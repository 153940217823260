<template>
    <div ref="loginView" class="login-view">

        <div style="height: 60px; display: flex;align-items: flex-end;font-weight: bold;color: #888888;padding: 0 25px;box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2);">
            <div @click="loginActive = 1" style="flex: 1;cursor: pointer;display: flex;flex-direction: column;align-items: center">
                <span :style="loginActive === 1 && 'color: #f16868'">登录</span>
                <div style="width: 30px;height: 4px;margin-top: 10px;" :style="loginActive === 1 && 'background-color: #f16868;'"></div>
            </div>

            <div @click="loginActive = 2" style="flex: 1;cursor: pointer;display: flex;flex-direction: column;align-items: center">
                <span :style="loginActive === 2 && 'color: #f16868'">注册</span>
                <div style="width: 30px;height: 4px;margin-top: 10px;" :style="loginActive === 2 && 'background-color: #f16868;'"></div>
            </div>
        </div>

        <img  :src="logoUrl" class="logo-img">

        <div v-if="loginActive === 1" class="login-form">
            <span class="ds-label" style="margin-top: 2px;font-weight: bold">{{ this.webName }}账号</span>
            <van-field label-width="0" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       :placeholder="`请输入${ this.webName }账号`" v-model="account" clearable>
            </van-field>

            <span class="ds-label" style="margin-top: 20px;font-weight: bold">密码</span>
            <van-field label-width="0" type="password" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       placeholder="请输入登录密码"  v-model="password" clearable/>

            <div style="width: 100%;position: relative;">
                <van-button type="info" @click="submitLogin" round native-type="submit" :loading="loading" style="width: 150px; height: 40px;margin-top: 30px;font-size: 14px;"
                            loading-text="登录中"
                            color="#4375f8">
                    登 录
                </van-button>
                <span style="color: #888888;font-size: 12px;position: absolute;bottom: 12px;right: 0;cursor: pointer;">忘记密码?</span>
            </div>

        </div>

        <div v-else class="login-form">
            <van-field label-width="0" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       :placeholder="`请输入${ this.webName }账号`" v-model="r_account" clearable>
            </van-field>

            <van-field label-width="0" type="password" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       placeholder="请输入密码"  v-model="r_password" clearable/>

            <van-field label-width="0" type="password" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       placeholder="请再次输入密码"  v-model="r_password1" clearable/>

            <van-checkbox v-model="isRead" icon-size="14px" shape="square" checked-color="#4375f8">
                <p style="font-size: 13px;">我已阅读并同意<span @click="openReadUrl" style="color: #4375f8;">《{{ this.webName }}用户协议》</span></p>
            </van-checkbox>

            <van-button @click="doRegister" type="info" round native-type="submit" :loading="loading" style="width: 150px; height: 40px;margin-top: 5px;font-size: 14px;"
                        loading-text="注册中" color="#4375f8">
                立即注册
            </van-button>
        </div>

        <div v-if="readDialogShow" class="my-read-dialog">
            <iframe v-show="true" class="iframe-box" :src="readUrl" frameborder="0" scrolling="y"
                    width="100%" height="100%" ></iframe>
        </div>

    </div>
</template>

<script>

import {Dialog, Toast} from "vant";
import crypto from "@/utils/crypto";
import {request} from "@/api/request";
import webConfig from "@/api/config";
import notice from "@/api/notice";

export default {
    name: 'HomeLogin',
    data() {
        return {
            account: '',
            password: '',
            loginActive: 1,
            r_account: '',
            r_password: '',
            r_password1: '',
            accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
            passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,20}$/,

            logoUrl: '',
            webName: '',
            loading: false,
            isLogin: false,
            showUserInfo: false,
            isRead: false,
            readDialogShow: false,
            readUrl: '',
        };
    },
    mounted() {
        this.initPage();
    },
    methods: {
        initPage() {
            let config = webConfig;
            this.webName = config.appName;
            this.logoUrl = config.logoUrl;
            this.readUrl = config.agreement;

            this.loginActive = 1;
            this.$nextTick(()=> {
                if (window.WheelEvent) {
                    this.$refs.loginView.addEventListener('wheel', function(event) {
                        event.preventDefault();
                    }, { passive: false });
                }
            });
        },
        openReadUrl() {
            notice.startIntent(this.readUrl);
        },
        submitLogin() {
            let ok = this.checkForm();
            if (ok !== 'ok') {
                Toast(ok);
                return;
            }
            this.loading = true;
            let options = {
                url: "/web/login",
                data: {
                    device: 'H5',
                    userName: this.account,
                    password: crypto.encrypt(this.password),
                }
            };
            request(options).then(res => {
                this.loading = false;
                if (res.data.success) {
                    localStorage.setItem('web_token', res.data.data.token);
                    localStorage.setItem('web_user', JSON.stringify(res.data.data));
                    localStorage.setItem('web_username', res.data.data.userName);
                    localStorage.setItem('web_headerImg', res.data.data.headerImg || '');
                    Toast('登录成功');
                    this.$parent.resetPage();
                } else {
                    Toast.fail(res.data.error.message);
                }
            });
        },
        checkForm() {
            let ok = 'ok';
            if (!this.account) {
                ok = "请输入"+ this.webName +"账号"
            } else if (!this.password) {
                ok = "请输入密码";
            } else if (!this.accountPattern.test(this.account)) {
                ok = this.webName + "账号为6-12位数字或字母";
            } else if (!this.passwordPattern.test(this.password)) {
                ok = "密码为6-20位数字字母特殊符号";
            }
            return ok;
        },
        generateUUID(){
            let d=new Date().getTime();
            let uuid ='xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        doRegister() {
            let uuid = localStorage.getItem("web_uuid");
            if (!uuid) {
                uuid = this.generateUUID();
                localStorage.setItem('web_uuid', uuid);
            }
            let ok = this.checkRegisterForm();
            if (ok !== 'ok') {
                Toast(ok);
                return;
            }
            this.loading = true;
            let options = {
                url: "/web/register",
                data: {
                    device: 'WEB',
                    udid: uuid,
                    userName: this.r_account,
                    password: crypto.encrypt(this.r_password),
                }
            };
            request(options).then(res => {
                this.loading = false;
                if (res.data.success) {
                    Toast('帐号注册成功');
                    this.loginActive = 1;
                    this.resetInput();
                } else {
                    Toast.fail(res.data.error.message);
                }
            });
        },
        checkRegisterForm() {
            if (!this.r_account) {
                return "请输入" + this.webName + "账号";
            }
            if (!this.accountPattern.test(this.r_account)) {
                return this.webName + "账号为6-12位数字字母";
            }
            if (!this.r_password) {
                return "请输入密码";
            }
            if (!this.passwordPattern.test(this.r_password)) {
                return "密码为6-20位数字字母特殊符号";
            }
            if (!this.r_password1) {
                return "请再次输入密码";
            }
            if (this.r_password !== this.r_password1) {
                return "两次密码输入不一致，请重新输入";
            }
            if (!this.isRead) {
                return '账号注册需要同意' + this.webName + '用户协议';
            }
            return "ok";
        },
    }
};
</script>

<style scoped>
.login-form {
    padding: 10px 25px;
    color: #444444;
}

.login-form ::-webkit-input-placeholder {
    color: #888888;
}
.login-form :-moz-placeholder {
    color: #888888;
}
.login-form ::-moz-placeholder {
    color: #888888;
}
.ds-label {
    display: inline-block;
    width: 100%;
    text-align: left;
    margin: 5px 4px 0;
    font-size: 14px;
}
.login-view {
    width: 350px;
    height: 450px;
    background-color: #ffffff;
    border-radius: 12px;
    z-index: 1002;
    position: relative;
    margin: 50px auto 0;
}
.logo-img {
    width: 70px;
    height: 70px;
    margin-top: 20px;
}
.my-read-dialog {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 900px) {
    .login-view {
        width: 80%;
        height: 420px;
        margin-top: 150px;
    }
    .logo-img {
        width: 50px;
        height: 50px;
        margin-top: 20px;
    }
}
</style>