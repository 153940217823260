<template>
    <div class="main-view">
        <home-nav  class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title">
            找游戏
        </div>
        <div style="width: 100%;min-height: calc(100vh - 380px); max-width: 900px;margin: 0 auto;justify-content: space-between;">
            <div class="p-title" style="width: 100%;background-color: #ffffff;border-radius: 10px;margin-top: 20px;height: fit-content">
                <div style="height: 70px;padding-left: 50px;display: flex;align-items: center;color: #666666">
                    <span>当前位置：</span>
                    <span style="margin-left: 10px;">首页</span>
                    <span style="margin-left: 10px;">></span>
                    <span style="margin-left: 10px;">所有游戏</span>
                </div>
                <div style="height: 0.5px;background-color: #e9e9e9;margin: 0 50px;"></div>
                <div style="display: flex;align-items: center;padding: 0 50px;height: 70px;width: 900px;">
                    <span style="font-size: 15px;font-weight: bold">游戏分类</span>
                    <span class="game_type"  v-for = "(item,index) in tagList"  :class="selectGameType === index && 'game_type_selected'" @click="selectType(index)">{{item.name}}</span>
                </div>
            </div>

            <div class="m-main-view">
                <div style="position: fixed; display: flex;flex-direction: column;background-color: #ffffff;height: 100vh;border-top-right-radius: 10px;overflow: hidden;">
                    <span class="m_game_type"  v-for = "(item,index) in tagList"  :class="selectGameType === index && 'm_type_selected'" @click="selectType(index)">{{item.name}}</span>
                </div>

                <div v-if="gameList.length > 0"  class="game-center" style="width: 100%;margin-top: 20px;display: flex;flex-wrap: wrap;justify-content: space-between;">
                    <div class="game-item-view" v-for="item in gameList" @click="open(item.game_id)">
                        <img :src="item.image_url" style="width: 70px;height: 70px;border-radius: 10px;">
                        <div style="display: flex;height: 70px; margin-left: 15px;flex-direction: column;justify-content: space-between;overflow: hidden">
                            <span class="game-name-one-line">{{item.game_name}}</span>
                            <div class="game-tag-view">
                                <span v-for="tag in item.tagList">{{tag}}</span>
                            </div>
                            <div style="font-size: 13px;color: #FFB300;text-align: left">
                                {{handlerTopic(item.game_topic)}}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else style="font-size: 20px;width: 100%;text-align: center;height: 300px;color: #888888;display: flex;align-items: center;justify-content: center">
                    暂无内容~
                </div>
            </div>

            <!--<div class="common-view">
                <span style="font-size: 14px;color: #666666;margin-right: 15px;">总计 {{total}} 条</span>
                <van-pagination v-model="currentPage" :total-items="total" :show-page-size="5" :items-per-page="pageSize"  @change="changePage">
                    <template #prev-text>
                        <span style="margin-left: 10px;">
                            上一页
                             <van-icon name="arrow-left"  style="margin-right: 10px;"/>
                        </span>
                    </template>
                    <template #next-text>
                        <span style="margin-left: 10px;">
                            下一页
                             <van-icon name="arrow"  style="margin-right: 10px;"/>
                        </span>
                    </template>
                    <template #page="{ text }">{{ text }}</template>
                </van-pagination>

            </div>-->

        </div>
        <home-footer class="m-hidden-view"></home-footer>
        <home-tabbar></home-tabbar>

    </div>

</template>

<script>
    import {request} from '@/api/request'
    import gonganbu from "@/assets/gonganbu.png";
    import {Toast} from 'vant'

    import gzhCodeView from "@/components/home/GzhCodeView";
    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import homeTabbar from "@/components/home/HomeTabbar";

    import { Tabbar, TabbarItem } from 'vant';
    import Vue from 'vue';
    Vue.use(Tabbar);
    Vue.use(TabbarItem);

    export default {
        name: 'gameHome',
        components: {
            homeNav,
            gzhCodeView,
            homeFooter,
            homeTabbar,
        },
        data() {
            return {
                gonganbu,
                active: 0,
                onActive: -1,
                linkActive: 0,
                gameSearchInput: '',
                emptyGameList: false,
                channel_id: '',
                gameList: [],
                safariStyle: '',
                tagList: [
                    {name: '全部', key: '0'},
                    {name: '卡牌', key: '1'},
                    {name: '传奇', key: '2'},
                    {name: '武侠', key: '3'},
                    {name: '角色', key: '4'},
                    {name: '养成', key: '5'},
                    {name: '三国', key: '6'},
                    {name: '模拟', key: '7'},
                    {name: '策略', key: '8'},
                    {name: '休闲', key: '9'},
                ],
                selectGameType: 0,
                currentPage: 1,
                total: 0,
                pages: 0,
                pageSize: 999,
                gameTag: '',
            }
        },
        mounted() {
            this.channel_id = this.$route.path.split('/')[2];
            this.queryGame();
            if (this.isSafari()) {
                this.safariStyle = 'padding-bottom: 113px';
            }
        },
        methods: {
            selectType(n) {
                this.selectGameType = n;
                if (n === 0) {
                    this.gameTag = '';
                }
                else {
                    this.gameTag = this.tagList[n].key;
                }
                this.currentPage = 1;
                this.queryGame();
            },
            changePage(e) {
                this.queryGame();
            },
            toDetail(gameId) {
                let urlPrefix = window.location.href.split('#')[0];
                let url = urlPrefix + '#/home/detail/' + gameId;
                console.log(url);
                window.open(url, '');
            },
            queryGame() {
                this.showLoading = true;
                let options = {
                    url:"/web/queryGameList",
                    data: {
                        pageNo: this.currentPage,
                        pageSize: this.pageSize,
                        params: {
                            game_topic : this.gameTag,
                        }
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        this.gameList = data.list;
                        this.total = data.total;
                        this.pages = data.pages;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            handlerTopic(t) {
                switch (t) {
                    case '1':
                        return '卡牌';
                    case '2':
                        return '传奇';
                    case '3':
                        return '武侠';
                    case '4':
                        return '角色';
                    case '5':
                        return '养成';
                    case '6':
                        return '三国';
                    case '7':
                        return '模拟';
                    case '8':
                        return '策略';
                    case '9':
                        return '休闲';
                }
            },
            open(gameId) {
                this.$router.push('/detail/1/' + gameId);
            },
            isSafari() {
                console.log(/Chrome/.test(navigator.userAgent));
                return (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent));
            }
        }
    }
</script>
<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #e6ecff;
    }
    .game_type {
        font-size: 14px;
        padding: 5px 15px;
        background-color: #eff0f5;
        border-radius: 3px;
        user-select: none;
        cursor: pointer;
        color: #666666;
        margin-left: 15px;
    }
    .m_game_type {
        width: 70px;
        font-size: 14px;
        padding: 15px 0;
        user-select: none;
        cursor: pointer;
        color: #666666;
    }
    .game_type_selected {
        font-weight: bold;
        color: #0e7dde;
        background-color: rgba(14, 125, 222, 0.16);
    }
    .m_type_selected {
        font-weight: bold;
        color: #0e7dde;
        background-color: rgba(14, 125, 222, 0.16);
    }

    .game-item-view {
        width: 430px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 20px 25px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .game-item-view:hover .game-name-one-line {
        color: #0e7dde;
    }
    .game-item-view:hover  {
        transform: translateY(-3px);
    }
    .game-name-one-line {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #333333
    }
    .game-tag-view {
        display: flex;
        color: #f15858;
        font-size: 12px;
        white-space: nowrap;
    }
    .game-tag-view span {
        margin-right: 10px;
        background-color: rgba(241, 88, 88, 0.1);
        padding: 2px 4px;
        border-radius: 2px;
    }
    /deep/.van-pagination__item {
        flex: unset;
        -webkit-flex: unset;
        -webkit-box-flex: unset;
    }
    /deep/.van-pagination__item--active {
    }
    .common-view {
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        height: 100px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .m-title {
        display: none;
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        width: 100%;
        height: 80px;
        text-align: left;
        align-items: center;
        padding-left: 20px;
        position: fixed;
        z-index: 999;
        background-color: #e6ecff;
    }
    @media screen and (max-width: 900px) {
        .p-title {
            display: none;
        }
        .game-center {
            margin-left: 80px;
        }
        .nav-back {
            position: fixed;
            z-index: 999;
        }
        .m-main-view {
            margin-top: 90px;
            margin-bottom: 50px;
        }
        .game-item-view {
            width: calc(100% - 90px) ;
            padding: 15px 15px;
        }
        .m-hidden-view {
            display: none !important;
        }
        .m-title {
            display: flex;
        }
    }

    @media screen and (min-width: 900px) {
        .m_game_type {
            display: none;
        }
    }
</style>

