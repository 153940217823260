<template>
    <div class="password-page">

        <div style="width: 100%;text-align: left;box-sizing: border-box;padding: 120px 0 0;">
            <div style="display: flex;align-items: center">
                <img :src="logoUrl" style="width: 70px;height: 70px;">
                <span style="font-size: 28px;font-weight: bold;margin-left: 15px;">{{ webName }}</span>
            </div>
            <div style="font-size: 16px;color: #666666; text-align: left;margin-top: 8px;">
                <span>重置密码</span>
            </div>
        </div>

        <div class="main-border">
            <van-form @submit="onSubmit" ref="submitForm">
                <span class="ds-label">账号</span>
                <van-field label-width="0" label=""  :placeholder="`请输入${ this.webName }账号`" class="border-input" v-model="account" clearable/>
                <span class="ds-label" style="margin-top: 10px;">新密码</span>

                <van-field label-width="0" type="password" label="" placeholder="请输入新密码" class="border-input" v-model="password" clearable/>
                <span class="ds-label" style="margin-top: 10px;">确认密码</span>

                <van-field label-width="0" type="password" label="" placeholder="请再次输入新密码" class="border-input" v-model="confirmPassword" clearable/>
                <span class="ds-label" style="margin-top: 10px;">验证码</span>

                <van-field label-width="0" label=""  v-model="verifyCode" type="number" clearable
                           placeholder="请输入验证码">
                    <template #button>
                        <van-count-down
                                v-show="showCountDown"
                                ref="countDown"
                                :time="60000"
                                :auto-start="false"
                                format="重新发送(ss秒)"
                                style="font-size: 14px;color: #888888"
                                @finish="finish"
                        />
                        <span  style="color: #4375f8;font-size: 14px;" v-show="!showCountDown" @click="sendSmsForReset">发送验证码</span>
                    </template>
                </van-field>

                <!--<div style="width: 100%;text-align: left;margin-top: 10px;font-size: 12px;color: #888888">
                    有疑问？
                    <span  @click="toCustomer" style="color: #4375f8">联系客服</span>
                </div>-->

                <div class="box-button">
                    <van-button type="info" block native-type="submit" :loading="loading" style="height: 40px;margin-top: 30px;font-size: 14px;"
                                loading-text="密码重置中"
                                color="#4375f8">
                        确认重置
                    </van-button>
                    <div style="font-size: 14px;margin-top: 15px;display: flex;align-items: center; justify-content: center;padding: 0 3px;">
                        <div @click="back" style="color: #4375f8">返回登录页面</div>
                    </div>

                </div>

            </van-form>

        </div>
    </div>
</template>

<script>

    import {sendSms,modifyPasswordByPhone} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import accountIcon from "@/assets/account.png";
    import passwordIcon from "@/assets/password.png";
    import verifyCodeIcon from "@/assets/verifyCode.png";
    import backIcon from "@/assets/back.png";
    import { Toast } from 'vant'
    import webConfig from "@/api/config";

    export default {
        name: 'resetPassword',
        data() {
            return {
                accountIcon,
                passwordIcon,
                verifyCodeIcon,
                backIcon,
                account: '',
                password: '',
                confirmPassword: '',
                verifyCode: '',
                accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
                passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+]{6,20}$/,
                codePattern: /^\d{6}$/,
                showCountDown: false,
                showSendText: true,
                disabledSend: false,
                loading: false,
                webName: '',
                logoUrl: require("@/assets/logo-skeleton.png"),
            };
        },
        mounted() {
          this.webName = webConfig.appName;
          this.logoUrl = webConfig.logoUrl;
        },
        methods: {
            back() {
                this.$router.back();
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                if (!this.codePattern.test(this.verifyCode)) {
                    Toast("验证码为6位数字");
                    return ;
                }
                this.loading = true;
                let params = {
                    device: 'H5',
                    newPassword: crypto.encrypt(this.password),
                    userName: this.account,
                    checkCode: this.verifyCode
                };
                modifyPasswordByPhone(params).then((res) => {
                    this.loading = false;
                    if (res.data.success === true) {
                        Toast('密码重置成功');
                        this.back();
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            sendSmsForReset() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }

                this.disabledSend = true;
                this.showSendText = false;
                this.showCountDown = true;
                this.$refs.countDown.start();
                let params = {
                    device: 'H5',
                    type: '5',
                    phone: this.account
                };
                sendSms(params).then((res) => {
                    if (res.data.success === true) {
                        Toast('验证码发送成功');
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            checkForm() {
                if (!this.account) {
                    return "请输入"+ this.webName + "账号";
                }
                if (!this.accountPattern.test(this.account)) {
                    return "账号为6-12位数字字母";
                }
                if (!this.password) {
                    return "请输入密码";
                }
                if (!this.passwordPattern.test(this.password)) {
                    return "密码为6-20位数字字母特殊符号";
                }
                if (!this.confirmPassword) {
                    return "请再次输入密码";
                }
                if (this.password !== this.confirmPassword) {
                    return "两次密码输入不一致，请重新输入";
                }
                return "ok";
            },
            toCustomer() {
                this.$router.push({path: '/customerService'});
            },
            finish() {
                this.disabledSend = false;
                this.showSendText = true;
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            checkPassword(val) {
                return this.password === this.confirmPassword;
            },
        }
    }
</script>

<style scoped>
    .password-page {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        height: 100vh;
        overflow: hidden;
        background-image: url(../../assets/bg-main.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 0 30px;
        box-sizing: border-box;
    }

    .main-border {
        text-align: center;
        border-radius: 12px;
        box-sizing: border-box;
        margin-top: 30px;
    }
    .ds-label {
        display: inline-block;
        width: 100%;
        text-align: left;
        margin: 5px 4px 0;
        font-size: 15px;
    }
    .down-input{
        margin-top: .8rem;
    }

    .box-button {
        margin-top: 15px;
    }
    .bottom-button{
        font-size: .8rem;
        margin-top: .8rem;
    }
    .van-cell{
        padding: 8px 4px;
    }
    .van-field{
        border-bottom: 1px solid #aaaaaa;
    }

</style>
