import serverArr from "@/api/servers";

const webConfig =  {
    //应用名称
    appName: "桃花里",
    //应用名称
    gameAppName: "桃花里游戏",
    //应用图标
    logoUrl: "https://tao648.cn/static/logo/tao_logo_alpha.png",
    //圆形logo
    logoRoundUrl: "https://tao648.cn/static/logo/tao_logo_circle.png",
    //微信公众号
    wechatUrl: "https://tao648.cn/static/logo/gzh.jpg",
    //应用二维码
    appqrUrl: "",
    //icp备案
    icp: "湘ICP备2024086040号-1",
    //公安备案
    publicSecurity: "",
    //版权所有
    copyright: "copyright © 2024 tao648.cn/ All Rights Resered.",
    //公司地址
    address: "湖南省长沙市开福区浏阳河街道福元西路涝湖二期30栋5单元508号-2245",
    //公司名称
    companyName: "长沙桃花里科技有限公司",
    //用户协议
    privacy :  "https://tao648.cn/static/agreement/privacy.html",
    //隐私政策
    agreement :  "https://tao648.cn/static/agreement/user.html",
    //QQ客服
    qqkfList: [
        {"info": "2788188888", "worktime": "09:00-22:00"}
    ],
    wxkfList: [
        {"info": "https://work.weixin.qq.com/kfid/kfc667b02ba1baf03bc", "worktime": "09:00-22:00"}
    ],
    telkfList: [

    ],
    //平台币名称
    coinName: "桃花币",
    //游戏加速器地址
    speedUrl: "https://tao648.cn/static/speed/a.html?genCode=true",
    //sdkweb地址
    sdkUrl: "https://tao648.cn/f/#/sdk/main"
}

export default webConfig