console.log('process.env', process.env)
const serverList = [
    {
        BASEURL: 'http://192.168.1.26:8402',
    },
    {
        BASEURL: 'https://tao648.cn/api',
    },
];
let serverArr;

serverArr = serverList[1]
export default serverArr
