<template>
    <div class="main-view">
        <div class="m-title">
            <div @click="back" style="margin-right: 5px;width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;">
                <van-icon name="arrow-left" size="20"/>
            </div>
            实名认证
        </div>

        <van-form @submit="onSubmit" ref="submitForm">
            <div class="form-view">
                <div v-if="inputEnable" style="color: #888888;font-size: 12px;text-align: left;margin: 0 15px;">
                    根据国家相关法律法规要求，进入游戏需要实名认证
                </div>

                <div v-else style="color: #888888;font-size: 12px;text-align: left">
                    该帐号已实名认证
                </div>

                <span class="ds-label">真实姓名</span>
                <van-field  label-class="inline-label" :readonly="!inputEnable" placeholder="请输入真实姓名"
                           style="margin-top: 2px;color: #ffffff;" v-model="realName" clearable/>

                <span class="ds-label">身份证号</span>
                <van-field  label-class="inline-label" :readonly="!inputEnable" placeholder="请输入身份证号"
                           style="margin-top: 2px;color: #ffffff;" v-model="carId" clearable/>

                <div v-show="inputEnable" class="box-button">
                    <van-button type="info" block native-type="submit" :loading="loading" size="small" round style="width: 160px;" loading-text="正在认证" color="#4375f8">
                        立即认证
                    </van-button>
                </div>

            </div>
        </van-form>


    </div>
</template>
<script>

    import {Toast} from "vant";
    import {webTokenRequest} from "@/api/request";

    export default {
        name: 'app-realName',
        components: {
        },
        data() {
            return {
                realName: '',
                carId: '',
                loading: false,
                inputEnable: true,
                realNamePattern: /^[\u4E00-\u9FA5]{1,5}$/,
                carIdPattern: /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][012])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
            }
        },
        mounted() {
            this.webConfig = webConfig;
        },
        methods: {
            back() {
                this.$router.back();
            },
            initData() {
                let user = JSON.parse(localStorage.getItem('user'));
                this.carId = user.cardNo;
                this.realName = user.realName;
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                this.loading = true;
                let options = {
                    url:"/web/personCheck",
                    data: {
                        cardNo: this.carId,
                        realName: this.realName,
                    }
                };
                webTokenRequest(options).then((res) => {
                    this.loading = false;
                    if (res.data.success === true) {
                        Toast.success('实名认证成功');
                        let user = JSON.parse(localStorage.getItem('user'));
                        user.isUserCheck = res.data.data;
                        localStorage.setItem('user', JSON.stringify(user));

                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            checkForm() {
                if (!this.realName) {
                    return "请输入真实姓名";
                }
                /*if (!this.realNamePattern.test(this.realName)) {
                    return "真实姓名格式错误";
                }*/
                if (!this.carId) {
                    return "请输入身份证号码";
                }
                if (!this.carIdPattern.test(this.carId)) {
                    return "身份证号码格式错误";
                }
                return "ok";
            },
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #e6ecff;
    }
    .m-title {
        display: flex;
        font-size: 18px;
        color: #333333;
        width: 100%;
        height: 60px;
        text-align: left;
        align-items: center;
        padding-left: 10px;
        position: fixed;
        z-index: 999;
        background-color: #e6ecff;
    }
    .ds-label {
        display: inline-block;
        width: 100%;
        text-align: left;
        margin: 15px 15px 0;
        font-size: 14px;
    }

    /deep/.inline-label {
        color: #aaaaaa !important;
    }
    .van-cell{
        padding: 8px 14px;
    }
    .van-cell::after {
        border-bottom: 1px solid #333333;
    }
    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
    .form-view {
        margin: 60px 15px 0;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 20px 10px 20px;
    }
</style>
