import axios from 'axios'
import SERVERS from '@/api/servers.js'
import {Toast} from "vant";

axios.defaults.baseURL = SERVERS.BASEURL

// http request 拦截器
axios.interceptors.request.use(
    config => {
        if(!config.headers['token']){
            config.headers['token'] = localStorage.getItem('token')
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// 返回状态判断
axios.interceptors.response.use(
    (response) => {
        // 登录token失效
        console.log('response', response.data);

        if (response.data.error && response.data.error.code === '911') {
            localStorage.removeItem('token');
            Toast('token已失效，请重新登录');

            let reg = /\/h5\/\d+\/\d+/;
            let path = window.location.href.split('?')[0].split('#')[1];
            let isH5 = reg.test(path)
            console.log('isH5', isH5);
            if (isH5) {
                window.location.reload()
            }
        }
        return response
    },
    (error) => {
        return error
    }
)
export default axios
