<template>
    <div class="main-view">
        <div class="m-title">
            <div @click="back" style="margin-right: 5px;width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;">
                <van-icon name="arrow-left" size="20"/>
            </div>
            关于
        </div>

        <div class="about-main">
            <div style="margin-top: 80px;display: flex;flex-direction: column;align-items: center;">
                <img :src="webConfig.logoUrl" style="width: 90px;height: 90px;">
                <span style="font-size: 20px;font-weight: bold;margin-top: 10px;">{{webConfig.appName}}游戏</span>
                <span style="margin-top: 10px;font-size: 15px;color: #666666;">版本号 1.0</span>
            </div>

            <div style="display: flex;flex-direction: column;align-items: center;font-size: 13px;color: #666666;margin-bottom: 30px;">
                <div style="margin-bottom: 5px;"><span style="color: #3763f5">《用户协议》</span> 和 <span style="color: #3763f5">《隐私政策》</span></div>
                <div style="margin-bottom: 5px;">Copyright © 2024 </div>
                <div>{{webConfig.companyName}}</div>
            </div>
        </div>


    </div>
</template>
<script>

    import webConfig from "@/api/config";

    export default {
        name: 'app-about',
        components: {
        },
        data() {
            return {
                webConfig: {},
            }
        },
        mounted() {
            this.webConfig = webConfig;
        },
        methods: {
            back() {
                this.$router.back();
            },

        }
    }
</script>

<style scoped>
    .about-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        padding-top: 60px;
        box-sizing: border-box;
    }
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #ffffff;
    }
    .m-title {
        display: flex;
        font-size: 18px;
        color: #333333;
        width: 100%;
        height: 60px;
        text-align: left;
        align-items: center;
        padding-left: 10px;
        position: fixed;
        z-index: 999;
        background-color: #ffffff;
    }
</style>
