<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

import webConfig from "@/api/config";

export default {
    name: 'App',
    mounted() {
        document.getElementById("web_title").innerText = webConfig.appName;
        document.getElementById("web_logo").href = webConfig.logoUrl;
    }
}
</script>

<style lang="scss">
body {
    overflow: hidden;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

</style>
