<template>
    <div class="main-view">
        <home-nav class="m-hidden-view"></home-nav>

        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title">
            <div @click="back" style="margin-right: 5px;width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;">
                <van-icon name="arrow-left" size="20"/>
            </div>
            我的游戏
        </div>
        <div style="width: 100%;min-height: calc(100vh - 380px); max-width: 900px;margin: 0 auto;justify-content: space-between;">
            <div class="p-title" style="width: 100%;background-color: #ffffff;border-radius: 10px;margin-top: 20px;height: fit-content">
                <div style="height: 70px;padding-left: 50px;display: flex;align-items: center;color: #666666">
                    <span>当前位置：</span>
                    <span style="margin-left: 10px;">首页</span>
                    <span style="margin-left: 10px;">></span>
                    <span style="margin-left: 10px;">我的游戏</span>
                </div>
            </div>

            <div class="game-list-view">
                <div class="game-area-title">
                    <span class="game-column-name">我玩过的游戏</span>
                    <img v-if="showBalance" @click="showBalance = !showBalance" src="../../assets/eye_hide.png" class="eye-button"/>
                    <img v-else @click="showBalance = !showBalance" src="../../assets/eye_show.png" class="eye-button" />
                </div>

                <div class="game-item-view game-list-item" v-for="item in myGameList" @click="open(item.gameId)">
                    <img :src="item.imgUrl" class="game-item-img">
                    <div class="game-item-info">
                        <div style="text-align: left">
                            <span class="game-name-one-line">{{item.gameName}}</span>
                            <span class="m-hidden-view" style="font-size: 13px;color: #FFB300;text-align: left;margin-left: 15px;">
                                {{handlerTopic(item.gameTopic)}}
                            </span>
                        </div>
                        <div class="m-hidden-view game-tag-view">
                            <span v-for="tag in item.tagList">{{tag}}</span>
                        </div>

                        <div class="p-hidden-view" style="flex: 1;color: #666666;font-size: 12px;margin-top: 10px;">
                            <div style="text-align: left;">
                                {{ webConfig.coinName }} : <span style="color: #f8b302;font-weight: bold;"> ￥ {{ showBalance ? item.balance : '****'}}</span>
                                <span style="margin-left: 20px;">绑币 :</span> <span style="color: #f8b302;font-weight: bold;"> ￥ {{ showBalance ? item.bindBalance : '****'}}</span>
                            </div>
                            <div style="margin-top: 10px;text-align: left">已拥有 <span style="color: #f56419;">{{ item.roleNames }}</span> {{ item.roleNumber > 2 ? '等' : ''}} {{ item.roleNumber }}个角色</div>
                        </div>

                    </div>
                    <div class="m-hidden-view" style="flex: 1;text-align: right;color: #666666;font-size: 13px;height: 50px;display: flex;justify-content: space-between;flex-direction: column;">
                        <div>
                            {{ webConfig.coinName }} : <span style="color: #f8b302;font-weight: bold;"> ￥ {{ showBalance ? item.balance : '****'}}</span>
                            <span style="margin-left: 20px;">绑币 :</span> <span style="color: #f8b302;font-weight: bold;"> ￥ {{ showBalance ? item.bindBalance : '****'}}</span>
                        </div>
                        <div>已拥有 <span style="color: #f56419;font-weight: bold;">{{ item.roleNames }}</span> {{ item.roleNumber > 2 ? '等' : ''}} {{ item.roleNumber }}个角色</div>
                    </div>

                </div>
            </div>

        </div>

        <home-footer class="m-hidden-view"></home-footer>

    </div>
</template>

<script>
    import {request, webTokenRequest} from '@/api/request'
    import {Toast} from 'vant'

    import gzhCodeView from "@/components/home/GzhCodeView";
    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import webConfig from "@/api/config";


    export default {
        name: 'my-game',
        components: {
            homeNav,
            gzhCodeView,
            homeFooter,
        },
        data() {
            return {
                active: 0,
                gameSearchInput: '',
                emptyGameList: false,
                myGameList: [],
                from: '',
                channelId: '1',
                webConfig: {},
                showBalance: false,
            }
        },
        mounted() {
            this.webConfig = webConfig;
            this.queryMyGame();
            if (this.isSafari()) {
                this.safariStyle = 'padding-bottom: 113px';
            }
        },
        methods: {
            back() {
                this.$router.back();
            },
            queryMyGame() {
                this.showLoading = true;
                let options = {
                    url:"/web/myGameList",
                };
                webTokenRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.myGameList = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            open(gameId) {
                //window.open(url, '');
                this.$router.push('/detail/' + this.channelId + '/' + gameId);
            },
            isSafari() {
                return (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent));
            },
            handlerTopic(t) {
                switch (t) {
                    case '1':
                        return '卡牌';
                    case '2':
                        return '传奇';
                    case '3':
                        return '武侠';
                    case '4':
                        return '角色';
                    case '5':
                        return '养成';
                    case '6':
                        return '三国';
                    case '7':
                        return '模拟';
                    case '8':
                        return '策略';
                    case '9':
                        return '休闲';
                }
            },
        }
    }
</script>
<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #e6ecff;
    }
    .game-item-view {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        box-sizing: border-box;
        transition: all 0.15s ease-out;
    }
    .game-list-item {
        margin-top: 20px;
    }

    .game-name-one-line {
        width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #333333
    }
    .game-tag-view {
        display: flex;
        color: #f15858;
        font-size: 12px;
    }
    .game-tag-view span {
        margin-right: 10px;
        background-color: rgba(241, 88, 88, 0.1);
        padding: 2px 4px;
        border-radius: 2px;
        white-space: nowrap;
    }

    .m-title {
        display: none;
        font-size: 18px;
        color: #333333;
        width: 100%;
        height: 80px;
        text-align: left;
        align-items: center;
        padding-left: 20px;
        position: fixed;
        z-index: 999;
        background-color: #e6ecff;
    }
    .game-list-view {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 25px;
        box-sizing: border-box;
    }

    .game-column-name {
        font-size: 30px;
        font-weight: bold;
        color: #1277f3;
        text-align: left;
        margin-bottom: 10px
    }
    .game-item-img {
        width: 80px;
        height: 80px;
        border-radius: 10px;
    }
    .game-item-info {
        display: flex;
        height: 65px;
        margin-left: 15px;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    }
    .game-area-title {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        align-items: flex-end;
    }
    .eye-button {
        width: 30px;
        height: 30px;
        cursor: pointer
    }
    @media screen and (max-width: 900px) {
        .p-title {
            display: none;
        }
        .game-name-one-line {
            font-weight: normal;
        }
        .m-hidden-view {
            display: none !important;
        }
        .m-title {
            display: flex;
        }
        .game-list-view {
            width: 90%;
            margin: 80px auto 60px;
            padding: 15px 15px;
        }
        .game-item-img {
            width: 80px;
            height: 80px;
        }
        .game-item-info {
            height: 75px;
        }
        .game-column-name {
            display: none;
        }
        .game-list-item {
            margin-top: 10px;
        }
        .game-area-title {
            justify-content: flex-end;
            padding-right: 20px;
        }
        .eye-button {
            width: 25px;
            height: 25px;
        }
    }

    @media screen and (min-width: 900px) {
        .p-hidden-view {
            display: none;
        }
    }
</style>

